import React, { useState } from "react";
import "../Styles/About.css";
import "bootstrap/dist/css/bootstrap.css";
import DottedBackground from "../Assets/images/dotted-background.png";
import about1 from "../Assets/images/about/about-1.png";
import about2 from "../Assets/images/about/about-2.png";
import about3 from "../Assets/images/about/about-3.png";
import about4 from "../Assets/images/about/about-4.png";
import about5 from "../Assets/images/about/about-5.png";
import about6 from "../Assets/images/about/about-6.png";
import about7 from "../Assets/images/about/about-7.png";
import about8 from "../Assets/images/about/about-8.png";
import about9 from "../Assets/images/about/about-9.png";
import about10 from "../Assets/sol/avatar.png";
import about11 from "../Assets/Avatar (1).png";
import about12 from "../Assets/fourth.png";
import about13 from "../Assets/Avatar (2).png";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
function About() {
  const [counterOn, setCounterOne] = useState(false);
  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="ourpatners_baner">
        <h2 className="Venture_heading">About</h2>

      </div>

      <div className="section_1">
        <div className="container">
          <div style={{ height: "50px" }}></div>
          <div className="row5 p-5 mobcont">
            <div className="col-md-6">
              <img
                src={about1}
                width={512}
                height={468}
                alt="about-us"
                className="img-fluid"
              />
            </div>

            <div className="new">
              <h1 className="text-primary" s>
                Reinventing Success with High Class Digital Solutions
              </h1>
              <p className="mt-5 textcent">
                From humble beginnings as a single person's venture, we've grown into a formidable team of 50+ skilled experts. As a leading web and mobile app development firm, SmartSphere Technologies empowers businesses with cutting-edge digital solutions. Our dynamic team of passionate individuals thrives on innovation, propelling SmartSphere Technologies to the forefront of IT services. Our unyielding dedication to utilizing technical expertise to overcome challenges is the driving force behind our enthusiasm.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center mt-5 mb-5">
        <h1 className="text-primary" style={{ fontWeight: "bolder" }}>Our Story</h1>
        <p className="mt-5 mb-5 afterworking">
          Following a stint as a software developer, our founder charted a distinct course. Fueled by a vision to revolutionize the world through IT solutions, SmartSphere Technologies came into being. Our distinctiveness stems from our commitment to blending innovation, creativity, and customer contentment. With a roster of satisfied clients, we persist as a guiding force for both businesses and individuals seeking to harness and embrace contemporary digital technology.
        </p>

        <div className="container5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: "auto", }}>
          <div className={`row d-flex justify-content-center  }`}
          >
            <div className={`col-md-15 }`}>
              <div className="our_story_card p-3 " >
                <div className="card-container">
                  <div className="card-body " >
                    <h3 className="text-white">What sets us apart from others</h3>
                    <div className="row5 mt-3">
                      <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: "auto", }}>
                        <img src={about2} alt="about" className="img-fluid" />
                        <p className="t mt-2 text-white ">
                          Best in-class IT <br /> Services & Solutions
                        </p>
                      </div>
                      <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: "auto", }}>
                        <img src={about3} alt="about" className="img-fluid" />
                        <p className="mt-2 text-white">
                          Innovation and <br /> Learning Everydays
                        </p>
                      </div>
                      <div className="col-md-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: "auto", }}>
                        <img src={about4} alt="about" className="img-fluid" />
                        <p className="mt-2 text-white">
                          Customers First <br /> Approach!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTrigger onEnter={() => setCounterOne(true)} onExit={() => setCounterOne(false)}>
        <div style={{ marginTop: "100px", padding: '14px' }} className="container ">
          <div className="row5">
            <div className="col-md-6">
              <div className="titleA">
                <span
                  style={{ fontWeight: 600, fontSize: "16px" }}
                  className="text-primary launch fw-bold"
                >
                  Launch faster
                </span>
                <h1 style={{ fontWeight: 700 }} className="text-primary">
                  Build something great
                </h1>
              </div>
              <p className="text-muted mt-3">



                We handle the hard work, providing you with essential data for a quicker business launch and growth.            </p>

              <div className="row5 mt-5 " style={{ display: 'flex' }}>


                <div className="col-6 text-center" >
                  <span
                    className="text-primary fontabout"
                    style={{ fontWeight: 600, fontSize: "40px" }}
                  >
                    {counterOn && <CountUp start={0} end={4000} duration={2} delay={0} />}+
                  </span>
                  <p style={{ fontWeight: 500, fontSize: 18 }}>
                    Global customers
                  </p>
                  <p className="text-muted">
                    We’ve helped over 4,000 amazing global companies.
                  </p>
                </div>

                <div className="col-6 text-center">
                  <span
                    className="text-primary fontabout"
                    style={{ fontWeight: 600, fontSize: "40px" }}
                  >
                    {counterOn && <CountUp start={0} end={600} duration={2} delay={0} />}%
                  </span>
                  <p style={{ fontWeight: 500, fontSize: 18 }}>
                    Return on investment
                  </p>
                  <p className="text-muted">
                    Our customers have reported <br /> an average of ~600% ROI.
                  </p>
                </div>

                <div className="col-6 text-center">
                  <span
                    className="text-primary fontabout"
                    style={{ fontWeight: 600, fontSize: "40px" }}
                  >
                    {counterOn && <CountUp start={0} end={10} duration={4} delay={0} />}K
                  </span>
                  <p style={{ fontWeight: 500, fontSize: 18 }}>
                    Global downloads
                  </p>
                  <p className="text-muted">
                    Our app has been <br /> downloaded over 10k times.
                  </p>
                </div>

                <div className="col-6 text-center">
                  <span
                    className="text-primary fontabout"
                    style={{ fontWeight: 600, fontSize: "40px" }}
                  >
                    {counterOn && <CountUp start={0} end={2000} duration={2} delay={0} />}+
                  </span>
                  <p style={{ fontWeight: 500, fontSize: 18 }}>5-star reviews</p>
                  <p className="text-muted">
                    Our app has been downloaded over 10k times.
                  </p>
                </div>
              </div>

            </div>

            <div className="col-md-6">
              <img
                height={655}
                width={550}
                src={about5}
                style={{ height: "80%" }}
                alt="about-5"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </ScrollTrigger>

      {/* <div className="text-center">
        <div className="our_team">
          <div style={{ height: "50px" }}></div>
          <span
            className="text-muted"
            style={{ fontWeight: 600, fontSize: 16 }}
          >
            We're hiring!
          </span>
          <h1 className="text-primary fw-bold mt-3">Meet our team.</h1>
          <p className="text-muted" style={{ fontWeight: 600 }}>
            Our philosophy is simple — hire a team of diverse, passionate people
            and foster a <br /> culture that empowers you to do you best work.
          </p>

          <div className="container">
            <div className="row mt-5">
              <div className="col-md-3 col-6">
                <img src={about6} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Olivia Rhye
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Founder & CEO
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about7} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Phoenix Baker
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Engineering Manager
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about8} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Lana Steiner
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Product Manager
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about9} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Demi Wilkinson
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Frontend Developer
                </span>
              </div>

              <div style={{ height: "50px" }}></div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3 col-6">
                <img src={about10} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Candice Wu
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Backend Developer
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about11} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Natali Craig
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Product Designer
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about12} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>Drew Cano</span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  UX Researcher
                </span>
              </div>
              <div className="col-md-3 col-6">
                <img src={about13} alt="about" className="img-fluid" />
                <br />
                <span style={{ fontWeight: 500, fontSize: 18 }}>
                  Orlando Diggs
                </span>
                <br />
                <span
                  style={{ fontWeight: 400, fontSize: 18, color: "#6941C6" }}
                >
                  Customer Success
                </span>
              </div>

              <div style={{ height: "50px" }}></div>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default About;
