import React from "react";
import "../Styles/HireMain.css";
import MainFirst from "../Assets/hireshopifyimage-1@2x.png";
import mainAbout from "../Assets/s23.png";
import Flexible from "../Assets/Flexible Hours.png";
import HireHour from "../Assets/Hire Team.png";
import Dedicated from "../Assets/Dedicated-Resource.png";
import Faq from "./Faq";
import HireCompo from "./HireCompo";

// ABBas

import Note from "../Assets/funtion.png";
import CV from "../Assets/Group.png";
import Meeting from "../Assets/Group.png";
import Avatar from "../Assets/avatar.png";
import Remote from "../Assets/monitor.png";
import Vector from "../Assets/Vector3.png";

import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/ame.png";
import UAE from "../Assets/uae.png";
import Fashions from "../Assets/pic1.png";
import Retail from "../Assets/pic3.png";
import AutoParts from "../Assets/autoparts.png";
import Fitness from "../Assets/apple.png";
import Furniture from "../Assets/pic5.png";
import Monitor from "../Assets/pic4.png";
import Beauty from "../Assets/beauty.png";
import Pharmaceutical from "../Assets/pharma.png";
import Rental from "../Assets/car.png";
import Banner from "../Assets/banner.png";

import pic1 from "../Assets/pic1.png";

import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";

import check from "../Assets/check.png";

import mainleft from "../Assets/mainleftimg.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";

import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import Flag from "./Flag";
import NewFaq from "./NewFaq";

const HireShopify = (props) => {
  const currentURL = window.location.href;
  console.log(currentURL);

  return (
    <div style={{ overflow: "hidden" }}>
      <section className="main-her-9009 p-4">
        <div className="container maind-v- vovo">
          <div className="row ">
            <div className="col-lg-6">
              <h2 className="main9080">Hire Dedicated Shopify Developers</h2>
              <p className="main-908">
                Build SEO-friendly and conversion-driven Shopify websites as you
                hire Shopify developers from SmartSphere Technologies. Now, create
                ready-to-use stores with a customized theme as you hire
                dedicated Shopify developers. You can rely on them for minor
                tweaks to integrations and migration.
              </p>
              <HashLink to={"#contact-us"}>
                <button className=" firt-main-btn">
                  Hire Shopify Developers
                </button>
              </HashLink>
            </div>
            <div className="col-lg-6">
              <img src={MainFirst} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5 pad--">
                <h2 className="about-main-conetnetn-h2">
                  Partner with the Seasoned Shopify Website Developers
                </h2>
                <p className="about-main-content-parap">
                  Our Shopify experts have a knack for building robust online
                  stores for small to Enterprise level businesses. Backed by 16+
                  years of experience, we can cater to your custom needs when
                  you hire Shopify experts. Be it expanding your store’s
                  functionalities or customizing your Shopify theme.<br></br>{" "}
                  <br></br> At SmartSphere Technologies, our Shopify experts are
                  ready to help you create the right eCommerce store using best
                  industry practices. Following the agile method, we deliver
                  your project in an iterative process, giving enough attention
                  to feedback and testing.<br></br> <br></br>
                  Hire professional Shopify developers with the right skills and
                  experience who can understand your requirements. Not just
                  that, the professionals can turn them into lucrative e-stores
                  within budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Contactsub />
      <section className="main-services-card-main-div p-5">
        <div className="container p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <center>
              <h1 className="main-serv-ces-h1-head">
                Our Shopify Developers’ Expertise
              </h1>
            </center>

            <div className="col-lg-2 col-md-4 col-sm-6 card-main-div-main-card set">
              <p className="card-top-main">Shopify ECommerce Development</p>
              <p className="main-top-bottom-content">
                Our Shopify developers utilize the platform's features to develop
                high-end websites with rich graphics for you. Hire Shopify developer
                USA and create a competitive and futuristic online store.
              </p>
            </div>


            <div className="col-lg-2 col-md-4 col-sm-6 card-main-div-main-card">
              <p className="card-top-main">Shopify Store Development</p>
              <p className="main-top-bottom-content">
                You can hire a Shopify developer to leverage smart features and build
                a unique yet user-friendly online store. Enhancing your user
                engagement is made easier by availing of eCommerce store development
                services.
              </p>
            </div>


            <div className="col-lg-2 col-md-4 col-sm-6 card-main-div-main-card">
              <p className="card-top-main">Shopify Theme Development</p>
              <p className="main-top-bottom-content">
                Clients recognize our developers' Shopify theme development expertise
                across the globe. Hire Shopify experts from us to build custom themes
                for the frontend and backend.
              </p>
            </div>


            <div className="col-lg-2 col-md-4 col-sm-6 card-main-div-main-card">
              <p className="card-top-main">Shopify Integration</p>
              <p className="main-top-bottom-content">
                Our Shopify specialists seamlessly integrate Shopify with payment
                gateways, custom functionalities, and third-party apps. All while
                ensuring the security and smooth functioning of your data.
              </p>
            </div>


            <div className="col-lg-2 col-md-4 col-sm-6 card-main-div-main-card">
              <p className="card-top-main">Shopify Migration Services</p>
              <p className="main-top-bottom-content">
                Migrate your existing shopping portal to Shopify while maintaining
                security and efficiency. Get it done by our Shopify experts today!
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="main-div-cli-lic">
        <div className="container ">
          <div className="row" style={{ marginBottom: "30px" }}>
            <center>
              <h3 className="new-head-phy pl-2">
                Why You Should Hire Someone to Build Shopify Stores
              </h3>
            </center>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src={Flexible} alt="" className="img-fluid p-5" />
              <p className="hour ">Transparent Conduct</p>
              <p className="new-hour-para">
                Remote Shopify app developers give you accessibility to all the
                development resources and are open to discussions. They provide
                periodic reports, access to tools, and streamlined communication
                to ensure everyone is on the same page.
              </p>
            </div>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src={HireHour} alt="" className="img-fluid p-5" />
              <p className="hour ">Cost Saving</p>
              <p className="new-hour-para">
                Worry no more about team management and infrastructural needs
                when you hire Shopify web developers. You only have to pay for
                their performance and time spent on the project, and they will
                take care of the rest.
              </p>
            </div>

            <div
              className="col-lg-4 col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src={Dedicated} alt="" className="img-fluid p-5" />
              <p className="hour ">Scalable Team</p>
              <p className="new-hour-para">
                Outsourcing Shopify programmers provide you with the flexibility
                to expand your business without compromising quality. Hiring
                professional Shopify developers makes it easier to stay ahead of
                the competition by leveraging their vast industry knowledge and
                platform expertise.
              </p>
            </div>
          </div>
        </div>
      </section>
      <HireCompo Title="Shopify" />
      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>

        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Hire Shopify Developers from SmartSphere?
                </h2>
                <p className="h2-h3-h4">
                  We search, test, hire, and train our Shopify developers to
                  ensure you don’t have to face any development challenges. When
                  you hire Shopify app developers from us, you can see our
                  commitment to customer satisfaction which gives you an edge
                  over your competitors.
                </p>
                <ul className="newul">
                  <li>Dedicated Shopify Website Developers</li>
                  <li>Industry Expertise</li>

                  <li>No Pre and Post-Hiring Responsibilities</li>
                  <li>On-time Project Deliveries</li>
                  <li>Complete Control</li>
                  <li>Flexibility in Time Zones</li>
                  <li>Clear Communication</li>
                  <li>100% Source Authorization</li>
                  <li>Maintenance and Support</li>
                  <li>Security and Compliance</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What is the cost to hire Shopify experts?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  The cost of hiring Shopify developers depends on your specific
                  business requirements. You can choose from flexible,
                  dedicated, or team-based pricing models for hiring Shopify
                  experts.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What are the essential skills for Shopify Developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Shopify developers should have practical experience in building
                e-commerce stores and apps using the platform. Proficiency in
                PHP, Ruby, NodeJS, and Python is important. When hiring
                dedicated Shopify developers, consider their experience,
                portfolio, and soft skills.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your Shopify Developers create custom Shopify Apps?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Certainly! Our experienced Shopify experts can develop custom
                apps tailored to your needs. Reach out to us with your project
                details for a personalized quote.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Do you build SEO-optimized Shopify websites and stores?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, our Shopify website developers create SEO-optimized
                websites and stores, ensuring improved search visibility and
                discoverability.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Can I hire a dedicated Shopify Web Developer on an hourly or
                  project basis?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                basis? Absolutely! You have the flexibility to hire dedicated
                Shopify developers or even a full team on an hourly basis or for
                specific projects. We offer engagement models like flexible
                hours, dedicated resources, and team-based hiring to match your
                project requirements.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your Shopify Developers design custom Shopify Themes?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, our skilled Shopify Plus developers regularly design custom
                themes for Shopify. These themes are recognized and utilized by
                businesses worldwide to create visually appealing and engaging
                online stores.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default HireShopify;
