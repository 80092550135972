import React from "react";
import "../Styles/HireMain.css";
import MainFirst from "../Assets/magneto1.png";
import mainAbout from "../Assets/magento2.png";
import Flexible from "../Assets/Flexible Hours.png";
import HireHour from "../Assets/Hire Team.png";
import Dedicated from "../Assets/Dedicated-Resource.png";
import Faq from "./Faq";

// ABBas

import Note from "../Assets/funtion.png";
import CV from "../Assets/Group.png";
import Meeting from "../Assets/Group.png";
import Avatar from "../Assets/avatar.png";
import Remote from "../Assets/monitor.png";
import Vector from "../Assets/Vector3.png";

import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/ame.png";
import UAE from "../Assets/uae.png";
import Fashions from "../Assets/pic1.png";
import Retail from "../Assets/pic3.png";
import AutoParts from "../Assets/autoparts.png";
import Fitness from "../Assets/apple.png";
import Furniture from "../Assets/pic5.png";
import Monitor from "../Assets/pic4.png";
import Beauty from "../Assets/beauty.png";
import Pharmaceutical from "../Assets/pharma.png";
import Rental from "../Assets/car.png";
import Banner from "../Assets/banner.png";

import pic1 from "../Assets/pic1.png";

import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";

import check from "../Assets/check.png";

import mainleft from "../Assets/mainleftimg.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";

import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import HireCompo from "./HireCompo";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import Flag from "./Flag";
import NewFaq from "./NewFaq";

const HireMagneto = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="main-her-9009 p-4">
        <div className="container maind-v- vovo">
          <div className="row p-2">
            <div className="col-lg-6">
              <h2 className="main9080">Hire Dedicated Magento Developers</h2>
              <p className="main-908">
                Build robust and feature-rich Magento websites as you hire
                Magento developers from SmartSphere Technologies. Now, create
                customized online stores with seamless performance and enhanced
                user experience by hiring dedicated Magento developers. Our
                experts are proficient in handling minor tweaks, integrations,
                and migrations.
              </p>
              <HashLink to={"#contact-us"}>
                <button className="firt-main-btn">
                  Hire Magento Developers
                </button>
              </HashLink>
            </div>
            <div className="col-lg-6">
              <img src={MainFirst} alt="" className="img-fluid magentoImg" />
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Partner with Seasoned Magento Website Developers
                </h2>
                <p className="about-main-content-parap">
                  At SmartSphere Technologies, we have a team of experienced Magento
                  experts who excel in building scalable online stores for
                  businesses of all sizes. With their extensive knowledge and
                  16+ years of industry experience, they are equipped to cater
                  to your custom needs when you hire Magento experts. From
                  expanding store functionalities to customizing Magento themes,
                  they have got you covered.<br></br> <br></br> Our Magento
                  experts at SmartSphere Technologies are committed to helping you
                  create an exceptional eCommerce store using industry best
                  practices. By following an agile methodology, we ensure
                  iterative development, thorough feedback incorporation, and
                  rigorous testing to deliver your project successfully.
                  <br></br> <br></br>
                  When you hire professional Magento developers with the right
                  skills and expertise, you can trust them to understand your
                  requirements and transform them into profitable online stores
                  within your budget.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contactsub />
      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <center>
              <h3 className="new-head-phy pl-2 hea">
                Our Magento Developers' Expertise
              </h3>
            </center>

            <div className="col-md-4 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">Magento ECommerce Development</p>
              <p className="main-top-bottom-content">
                Leverage the power of Magento's extensive features and
                functionalities with our skilled Magento developers. They can
                build high-performing online stores with visually appealing
                designs that cater to your unique business requirements.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Store Development</p>
              <p className="main-top-bottom-content">
                Hire Magento developers to create user-friendly online stores
                with smart features that enhance user engagement. Our developers
                specialize in delivering eCommerce store development services
                that drive conversions and boost sales.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Theme Development</p>
              <p className="main-top-bottom-content">
                Our developers are recognized for their expertise in Magento
                theme development. By hiring Magento experts from SmartSphere
                Technologies, you can have custom themes designed for both the
                frontend and backend of your online store.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Magento Integration</p>
              <p className="main-top-bottom-content">
                Seamlessly integrate Magento with payment gateways, third-party
                applications, and custom functionalities with the help of our
                Magento specialists. They ensure smooth operation and data
                security throughout the integration process.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="main-div-cli-lic">
        <div className="container ">
          <div className="row" style={{ marginBottom: "30px" }}>
            <center>
              <h3 className="new-head-phy pl-2">
                Why You Should Hire Someone to Build Magento Stores
              </h3>
            </center>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}

            >
              <img src={Flexible} alt="" className="img-fluid p-5" />
              <p className="hour ">Transparent Conduct</p>
              <p className="new-hour-para">
                When you hire remote Magento developers, you gain access to
                development resources and open channels of communication. Our
                developers provide regular reports, maintain transparency, and
                ensure streamlined communication to keep everyone on the same
                page.
              </p>
            </div>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src={HireHour} alt="" className="img-fluid p-5" />
              <p className="hour ">Cost Saving</p>
              <p className="new-hour-para">
                By hiring Magento web developers, you eliminate the need for
                team management and infrastructure investment. You only pay for
                the developers' performance and the time spent on your project,
                while they take care of the rest.
              </p>
            </div>

            <div
              className="col-lg-4 col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{ padding: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
            >
              <img src={Dedicated} alt="" className="img-fluid p-5" />
              <p className="hour ">Scalable Team</p>
              <p className="new-hour-para">
                Outsourcing Magento programmers allows you to scale your
                business without compromising quality. Hiring professional
                Magento developers gives you access to their industry knowledge
                and platform expertise, helping you stay ahead of the
                competition.
              </p>
            </div>
          </div>
        </div>
      </section>
      <HireCompo Title="Magento" />
      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>

        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose SmartSphere to Hire Magento Developers?
                </h2>
                <p className="h2-h3-h4">
                  We have the resources to help you unleash the full power of
                  Magento 2 to develop a custom solution that suits your
                  business needs.
                </p>
                <ul className="newul">
                  <li>Experienced Magento Developers</li>
                  <li>Problem Solving and Communication Skills</li>

                  <li>Industry Expertise</li>
                  <li>In-depth Knowledge of Magento</li>
                  <li>On-time Project Deliveries</li>
                  <li>Flexibility in Time Zones</li>
                  <li>Streamlined Project Management</li>
                  <li>Maintenance and Support</li>
                  <li>Security and Compliance</li>
                  <li>No Obligation Quote</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What is the cost to hire dedicated Magento developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  The cost of hiring dedicated Magento developers depends on the
                  specific requirements of your project. Different pricing
                  models, such as hourly rates, dedicated resources, or
                  team-based hiring, are available. Contact us for a
                  personalized quote.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What skills should Magento Developers possess?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Magento developers should have extensive experience in
                developing e-commerce websites and applications using the
                platform. Proficiency in PHP, MySQL, HTML, CSS, JavaScript, and
                familiarity with Magento extensions and themes is essential.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your Magento Developers build custom Magento extensions?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, our skilled Magento developers are capable of building
                custom extensions tailored to your business needs. Share your
                requirements with us to get a detailed estimate for hiring a
                Magento developer.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Do you provide support and maintenance for Magento websites?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we offer comprehensive support and maintenance services for
                Magento websites. Our team ensures that your Magento store runs
                smoothly, performs optimally, and stays up to date with the
                latest security patches.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Can I hire dedicated Magento developers on an hourly basis?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Certainly! We provide flexible hiring models, including hourly
                rates, to meet your project requirements. You can hire dedicated
                Magento developers for specific tasks or projects as per your
                convenience.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Can your Magento Developers optimize the performance of my
                  Magento store?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Absolutely! Our Magento developers have expertise in optimizing
                the performance of Magento stores. We analyze your store's
                performance, identify bottlenecks, and implement necessary
                optimizations to enhance its speed and scalability.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default HireMagneto;
