import React from "react";
import "../Styles/HireMain.css";
import MainFirst from "../Assets/Django1.png";
import mainAbout from "../Assets/django2.png";
import Flexible from "../Assets/Flexible Hours.png";
import HireHour from "../Assets/Hire Team.png";
import Dedicated from "../Assets/Dedicated-Resource.png";
import Faq from "./Faq";

// ABBas

import Note from "../Assets/funtion.png";
import CV from "../Assets/Group.png";
import Meeting from "../Assets/Group.png";
import Avatar from "../Assets/avatar.png";
import Remote from "../Assets/monitor.png";
import Vector from "../Assets/Vector3.png";

import Australia from "../Assets/aus.png";
import Pakistan from "../Assets/pak.png";
import America from "../Assets/ame.png";
import UAE from "../Assets/uae.png";
import Fashions from "../Assets/pic1.png";
import Retail from "../Assets/pic3.png";
import AutoParts from "../Assets/autoparts.png";
import Fitness from "../Assets/apple.png";
import Furniture from "../Assets/pic5.png";
import Monitor from "../Assets/pic4.png";
import Beauty from "../Assets/beauty.png";
import Pharmaceutical from "../Assets/pharma.png";
import Rental from "../Assets/car.png";
import Banner from "../Assets/banner.png";

import pic1 from "../Assets/pic1.png";

import pic3 from "../Assets/pic3.png";
import pic4 from "../Assets/pic4.png";
import pic5 from "../Assets/pic5.png";
import autoparts from "../Assets/autoparts.png";
import pharma from "../Assets/pharma.png";
import apple from "../Assets/apple.png";
import beauty from "../Assets/beauty.png";
import car from "../Assets/car.png";

import check from "../Assets/check.png";

import mainleft from "../Assets/mainleftimg.png";
import pe1 from "../Assets/pe1.png";
import pe2 from "../Assets/pe2.png";
import pe3 from "../Assets/pe3.png";
import pe4 from "../Assets/pe4.png";
import pe5 from "../Assets/pe5.png";
import mainbg from "../Assets/maingbg.png";
import left2 from "../Assets/left2.png";
import microsoft from "../Assets/microsoft.png";
import js from "../Assets/js.png";
import php from "../Assets/php.png";

import salesforce from "../Assets/salesforce.png";
import odoo from "../Assets/odoo.png";
import netcore from "../Assets/netCore.png";
import HireCompo from "./HireCompo";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import Flag from "./Flag";
import NewFaq from "./NewFaq";
const Django = (props) => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="main-her-9009 p-4">
        <div className="container maind-v- vovo">
          <div className="row p-2">
            <div className="col-lg-6">
              <h2 className="main9080">
                Hire Django Developers with Confidence
              </h2>
              <p className="main-908">
                Hire Node JS developers from SmartSphere to develop high-performing,
                scalable, real-time web apps. Our Node JS professionals are
                experts in providing cutting-edge solutions with hands-on
                GraphQL, MongoDB, MVC, CI/CD pipeline, serverless architecture,
                and more. Choose the best team for your next project!
              </p>
              <HashLink to={"#contact-us"}>
                {" "}
                <button className="firt-main-btn">Hire NodeJS Expert</button>
              </HashLink>
            </div>
            <div className="col-lg-6">
              <img src={MainFirst} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">Hire NodeJS Expert</h2>
                <p className="about-main-content-parap">
                  SmartSphere is a leading Shopify development company that offers
                  end-to-end custom Shopify development services. We have an
                  impressive track record of delivering diverse IT solutions and
                  services to our clients for over 16 years.<br></br> <br></br>{" "}
                  With SmartSphere , you get all the valuable solutions for your
                  eCommerce needs. We have crafted our Shopify store development
                  services in such a way that it offers easy management of
                  content, product, and orders. This allows you to have complete
                  control over your Shopify store. We have a team of Shopify
                  developers with in-depth <br></br> <br></br>
                  knowledge of all the cutting-edge technology stacks that can
                  accommodate your complex Shopify development requirements. We
                  help you develop, design, test, deploy, and maintain your
                  eStore. This helps your users to have a smooth experience.
                  SmartSphere is the first choice for startups and enterprises that
                  want to take their entire store online and provide their users
                  with a flawless experience. This is the major reason why we
                  have been able to serve more than 1000 clients. You can be the
                  next in that illustrious list of our clients by successfully
                  fulfilling your business objectives with us!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <center>
              <h1 className="main-serv-ces-h1-head">
                Benefits of Hiring Offshore Dedicated OpenCart Developers
              </h1>
            </center>
            <center>
              <p className="main-services-para-main-card-top py-3">
                We offer all-inclusive Shopify services that help add more
                functionality, monitor performance, improve sales channels &
                inventory management, and many more.
              </p>
            </center>
            <div className="col-md-2 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">Store Setup & Configuration</p>
              <p className="main-top-bottom-content">
                Set up and configure your Shopify store per your business
                objectives and goals. Being amongst the leading Shopify
                development companies, we help you launch your online store in
                record time.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Store Setup & Configuration</p>
              <p className="main-top-bottom-content">
                Set up and configure your Shopify store per your business
                objectives and goals. Being amongst the leading Shopify
                development companies, we help you launch your online store in
                record time.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Store Setup & Configuration</p>
              <p className="main-top-bottom-content">
                Set up and configure your Shopify store per your business
                objectives and goals. Being amongst the leading Shopify
                development companies, we help you launch your online store in
                record time.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Store Setup & Configuration</p>
              <p className="main-top-bottom-content">
                Set up and configure your Shopify store per your business
                objectives and goals. Being amongst the leading Shopify
                development companies, we help you launch your online store in
                record time.
              </p>
            </div>

            <div className="col-md-2 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Store Setup & Configuration</p>
              <p className="main-top-bottom-content">
                Set up and configure your Shopify store per your business
                objectives and goals. Being amongst the leading Shopify
                development companies, we help you launch your online store in
                record time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="main-div-cli-lic">
        <div className="container ">
          <div className="row" style={{ marginBottom: "30px" }}>
            <center>
              <h3 className="new-head-phy pl-2">
                Ways to Hire Our Shopify Specialists
              </h3>
            </center>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={Flexible} alt="" className="img-fluid p-5" />
              <p className="hour ">Flexible Hours</p>
              <p className="new-hour-para">
                You can hire Shopify experts from us on an hourly basis. In this
                model, you will only have to pay for the time and resources
                spent on the project. The highlights include weekly billing with
                flexible duration. You get 100% source code authorization.
              </p>
            </div>

            <div
              className="col-lg-4  col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={HireHour} alt="" className="img-fluid p-5" />
              <p className="hour ">Flexible Hours</p>
              <p className="new-hour-para">
                You can hire Shopify experts from us on an hourly basis. In this
                model, you will only have to pay for the time and resources
                spent on the project. The highlights include weekly billing with
                flexible duration. You get 100% source code authorization.
              </p>
            </div>

            <div
              className="col-lg-4 col-sm-12 col-sm-12 vbl-card-sli  text-center m-auto"
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={Dedicated} alt="" className="img-fluid p-5" />
              <p className="hour ">Flexible Hours</p>
              <p className="new-hour-para">
                You can hire Shopify experts from us on an hourly basis. In this
                model, you will only have to pay for the time and resources
                spent on the project. The highlights include weekly billing with
                flexible duration. You get 100% source code authorization.
              </p>
            </div>
          </div>
        </div>
      </section>

      <HireCompo Title="Django" />

      <div className="container mt-5">
        <h1 className="heading">Industries We Serve</h1>
        <h6 className="subHead mb-5">
          We serve a wide range of industries, providing innovative solutions
          tailored to their unique needs.
        </h6>
        <div class="container container-fluid">
          <div class="row">
            <div class="col-md-4 col-sm-12 column border_right_bottom">
              <img src={pic1} />
              <h5 className="name">Fashions & Clothing</h5>
            </div>
            <div class="col-md-4  col-sm-12  column border_right_bottom">
              <img src={pic3} />
              <h5 className="name">Retail</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={autoparts} />
              <h5 className="name">Auto Parts</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={pharma} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right_bottom">
              <img src={apple} />
              <h5 className="name">Fitness & Nutrition</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_bottom">
              <img src={pic5} />
              <h5 className="name">Furniture</h5>
            </div>
          </div>
          <div className="row">
            <div class="col-md-4   col-sm-12 column border_right">
              <img src={pic4} />
              <h5 className="name">Pharmaceutical</h5>
            </div>
            <div class="col-md-4  col-sm-12 column border_right">
              <img src={beauty} />
              <h5 className="name">Beauty And Personal Care</h5>
            </div>
            <div class="col-md-4  col-sm-12 column ">
              <img src={car} />
              <h5 className="name">Rental</h5>
            </div>
          </div>
        </div>
      </div>

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Innovation Led Microsoft Dynamics Implementation
                </h2>
                <p className="h2-h3-h4">
                  We combine the power of platforms and technologies with our
                  innovation and industry expertise to drive large-scale,
                  customer-centric transformation.
                </p>
                <ul className="newul">
                  <li>Talented Drupal Professionals</li>
                  <li>Proven Experience in CRM solutions</li>

                  <li>100% Source Code Authorization</li>
                  <li>Flexible Engagement Models</li>
                  <li>On-time Delivery of Projects</li>
                  <li>Dynamic Development</li>
                  <li>Work Experience</li>
                  <li>Confidentiality and Integrity</li>
                  <li>Round-the-clock Technical Support</li>
                  <li>Error-free Solutions</li>
                  <li>Streamlined Project Management</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  {" "}
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What is the expense associated with recruiting a dedicated
                  Django developer?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  SmartSphere Technology provides adaptable engagement structures for
                  enlisting Python Django developers. The precise hiring
                  expenses hinge on your prerequisites and the intricacies of
                  your project. For comprehensive details, feel free to reach
                  out to our proficient Django specialists.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  How does one go about recruiting accomplished Django
                  developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                The process begins with sharing your requirements. Our experts
                will contact you, understand your needs, and provide CVs for
                your consideration. After shortlisting, you can evaluate and
                interview Django developers to select the right fit for your
                business.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  Are you able to assist me in creating my website using Django?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Absolutely, our team of Django specialists is well-equipped to
                assist you in crafting bespoke websites and mobile applications
                that cater to your specific needs.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Could you provide assistance with migrating my current
                  application to Django?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Our team comprises Python developers experienced in aiding
                clients in transitioning from legacy systems to Python for
                improved performance and user experience. Leveraging our junior
                Django developers, we can facilitate a seamless migration of
                your current application to the Django framework.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default Django;
