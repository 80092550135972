import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Faq from "./Faq";
import Hidemob from "../Assets/pix.png";
const SalesForce = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images">
        <div className="container  main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-6 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Salesforce Development Services
              </h1>
              <p className="main-hero-para-main-div">
                Harness the potential of Salesforce through the expertise of
                SmartSphere Technologies, a dynamic Salesforce development company.
                We specialize in creating and implementing diverse Salesforce
                applications tailored for Sales Cloud, Service Cloud, NonProfit
                Cloud, Lightning framework, Visualforce, and APEX code. Maximize
                the benefits of our Salesforce development services, CRM
                strategies, and Salesforce products today!
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  Build Enterprise Level Solutions with the Best Salesforce
                  Development Company in USA
                </h2>
                <p className="about-main-content-parap">
                  With over 16 years of experience serving more than 100
                  clients, SmartSphere Technologies excels in catering to diverse
                  industry verticals. Our Salesforce development services are
                  focused on delivering high-quality solutions to our customers.
                  As a Salesforce ISV partner, we offer a comprehensive range of
                  solutions including custom native application development,
                  Salesforce consultancy, Salesforce eCommerce and cloud
                  integration, Salesforce migration, and Salesforce
                  implementation, along with reliable support and maintenance.
                  <br></br> <br></br> Our team possesses domain expertise in
                  Salesforce sales cloud, Salesforce service cloud, Lightning
                  frameworks, REST/SOAP API, Pardot, APEX code, and more. As a
                  trusted Salesforce ISV partner, we prioritize 100% customer
                  satisfaction. <br></br> <br></br>
                  As one of the leading Salesforce development companies, we
                  have successfully helped clients from various locations expand
                  their businesses through our innovative Salesforce Development
                  Services. Let us assist you in creating a digital experience
                  that drives growth.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5  ">
        <div className="contianer p-md-5">
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head">
              Salesforce Development Services Verticals
            </h1>

            <p className="main-services-para-main-card-top py-3">
              Transform your digital transformation with our comprehensive{" "}
              <br></br>
              Salesforce development services, delivering highly intuitive{" "}
              <br></br>
              solutions for an exceptional user experience.<br></br>
            </p>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Consultation</p>
              <p className="main-top-bottom-content">
                Partner with our experienced Salesforce consultants to define
                and align your business objectives, maximizing the potential of
                Salesforce development services.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Implementation</p>
              <p className="main-top-bottom-content">
                Analyze your unique business needs and leverage our Salesforce
                expertise to implement the ideal solution, enabling you to scale
                and optimize your operations.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Custom Development</p>
              <p className="main-top-bottom-content">
                Unlock the full potential of Salesforce with our custom
                development services, tailored to enhance ROI, boost team
                productivity, and deliver a superior user experience.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Salesforce Lightning</p>
              <p className="main-top-bottom-content">
                Collaborate with our Salesforce ISV-certified developers to
                build responsive applications across devices, utilizing our deep
                knowledge of modules such as LWC and Aura.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">AppExchange Development</p>
              <p className="main-top-bottom-content">
                Harness the skills of our talented Salesforce developers to
                create applications, extensions, bolt solutions, and components,
                seamlessly uploadable to the AppExchange.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              SmartSphere Technologies A Veteran Salesforce Development Company
            </h1>
            <p
              className="main-services-para-main-card-top py-3"
              style={{ color: "white" }}
            >
              Experience the convenience of accessing comprehensive Salesforce
              consultation, development, migration, integration, and <br></br>
              implementation services from a single reliable source.
            </p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Dedicated Team</p>
                  <p className="arricval-card-content-main-last-top">
                    Collaborate with proactive experts aligned with your
                    business objectives, leveraging their expertise to achieve
                    your desired goals and provide an exceptional customer
                    experience.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    On-Demand Scalability
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Enjoy the flexibility to scale your team and customize
                    solutions as per changing conditions and evolving business
                    demands with our on-demand Salesforce services.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Support And Maintenance
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Leverage the expertise of our Salesforce developers and
                    access prompt resolution of queries. Benefit from our 24/7
                    support, on-time delivery, and efficient turnaround times.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Salesforce Services
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Services Because Our team of skilled developers excels in
                    understanding your requirements and devising effective
                    strategies to harness the power of agile methodology,
                    ensuring reduced total cost of ownership (TCO) and faster
                    time to market.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Clients Choose SmartSphere Technologies for Salesforce Services
                  Because
                </h2>
                <p className="h2-h3-h4">
                  Our team of skilled developers excels in understanding your
                  requirements and devising effective strategies to harness the
                  power of agile methodology, ensuring reduced total cost of
                  ownership (TCO) and faster time to market.
                </p>
                <ul className="newul">
                  <li>Salesforce ISV Partners</li>
                  <li>Certified and Verified Salesforce Experts </li>

                  <li>Scalability on Demand</li>
                  <li>Adaptable Engagement Models</li>
                  <li>Cost-Efficient Solution</li>
                  <li>Timely Delivery</li>
                  <li>Agile Development Approach</li>
                  <li>Code Authorization</li>
                  <li>Maximum Customer Satisfaction</li>
                  <li>Round-the-Clock Support and Maintenance</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What are the benefits of Salesforce application development
                  for my organization?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  Salesforce application development offers enhanced
                  functionality, seamless integration, improved automation,
                  streamlined processes, and enhanced user experience.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Does your company have certified Salesforce developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we have certified Salesforce developers who excel at
                solving complex problems. Hire our certified Salesforce
                developers to drive your project success.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What is the difference between Salesforce Developer and
                  Salesforce Development Company?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                When you hire a Salesforce developer, they become part of your
                team, whereas a Salesforce development company handles
                end-to-end development processes. Choose based on your project
                requirements.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Which industries do you cater to with Salesforce application
                  development services?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We cater to various industries, including healthcare,
                manufacturing, finance, retail, and more, with our Salesforce
                application development services.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What app development methodologies does your company follow?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We follow Agile methodology and have certified Agile and SCRUM
                developers experienced in various development methodologies.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What other verticals does SmartSphere Technologies serve in
                  Salesforce application development?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                In addition to Salesforce application development, we provide
                consulting, implementation, migration, integration, maintenance,
                and security services for a comprehensive Salesforce solution.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default SalesForce;
