import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Faq from "./Faq";
import Hidemob from "../Assets/pix.png";
const SalesApplication = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images">
        <div className="container p-4 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-8 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Salesforce Application Development Solutions
              </h1>
              <p className="main-hero-para-main-div">
                Maximize the potential of Salesforce CRM through our bespoke
                Salesforce App Development services, catering to employee
                engagement and customer experiences. Leverage the expertise of
                our Salesforce ISV partner to build secure, compliant, and
                high-performing apps that scale effectively.
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  Empower, Connect, and Personalize through Custom Salesforce
                  App Development
                </h2>
                <p className="about-main-content-parap">
                  In this digital era, it is crucial to connect with customers
                  wherever they are. Why not consider constructing a
                  personalized Salesforce application that streamlines business
                  processes and facilitates access to vital information?
                  <br></br> <br></br>We are ready to support you in optimizing
                  your business achievements through tailored solutions. Whether
                  you aim to enhance the efficiency of your existing system or
                  require a platform to revolutionize the customer experience,
                  our team of experts can seamlessly fulfill your business
                  objectives. <br></br> <br></br>
                  With over ten years of experience in elevating customer
                  experiences and expanding CRM capabilities within specified
                  timelines, we are positioned as your reliable partner. As a
                  dependable Salesforce app development firm, we empower you to
                  become a superhero for both your customers and employees.
                  Reach out to us for all your Salesforce app development
                  requirements, regardless of the size of your business. We are
                  committed to delivering customized solutions!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5 ">
          <div className="row p-2 ">
            <h1 className="main-serv-ces-h1-head ">
              Experience Our Salesforce Custom Application Development Solutions
            </h1>

            <p className="main-services-para-main-card-top py-3">
              Transform your concepts into tangible results with our range of{" "}
              <br></br>
              Salesforce custom app development services.
            </p>

            <div className="col-md-4 col-sm-8 card-main-div-main-card ">
              <p className="card-top-main">Design and Development Expertise</p>
              <p className="main-top-bottom-content">
                As your reliable Salesforce ISV partner, we offer end-to-end
                design and development services for AppExchange Apps. Expertise
                includes lightning components, Visualforce pages, and custom
                APIs.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Compliance Support</p>
              <p className="main-top-bottom-content">
                We provide security reviews before AppExchange submission,
                including custom testing for Salesforce vulnerabilities,
                ensuring a successful listing.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Lightning Compatibility</p>
              <p className="main-top-bottom-content">
                Our experts develop Lightning-ready, Salesforce-compatible apps
                tailored to your business.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">AppExchange Consulting</p>
              <p className="main-top-bottom-content">
                Comprehensive consulting services for AppExchange development
                and implementation. Includes concept analysis, solution
                specification, architecture design, integration research, MVP
                development, and competitor analysis.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              Our Salesforce Application Development Approach
            </h1>
            <p
              className="main-services-para-main-card-top py-3"
              style={{ color: "white" }}
            ></p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Consultation</p>
                  <p className="arricval-card-content-main-last-top">
                    Our experts gather crucial information and customizations
                    needed for your Salesforce application.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Planning</p>
                  <p className="arricval-card-content-main-last-top">
                    We create a comprehensive roadmap that includes ideas,
                    milestones, management strategies, and budget
                    considerations.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Mockups</p>
                  <p className="arricval-card-content-main-last-top">
                    A prototype is designed to provide a real-time visualization
                    of your custom Salesforce application.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">Development</p>
                  <p className="arricval-card-content-main-last-top">
                    Once mockups are approved, we begin agile development,
                    keeping you informed at every sprint.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose SmartSphere Technologies for Salesforce Mobile App
                  Development?
                </h2>
                <p className="h2-h3-h4">
                  With over 16 years of industry experience, we specialize in
                  providing customized solutions to businesses across various
                  industry verticals. Here are the advantages of teaming up with
                  us.
                </p>
                <ul className="newul">
                  <li>Experienced and Certified Salesforce Developers</li>
                  <li>Improved Operational Efficiency</li>

                  <li>Budget-Friendly Solutions</li>
                  <li>Adaptable Engagement Models</li>
                  <li>Robust Data Safety and Security</li>
                  <li>Timely Project Delivery</li>
                  <li>Efficient Communication Channels</li>
                  <li>Agile Methodology Implementation</li>

                  <li>Rigorous Quality Assurance</li>
                  <li>Complete Customer Satisfaction</li>
                  <li>Round-the-Clock Support and Maintenance</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What are the benefits of Salesforce application development
                  for my organization?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  Salesforce application development offers enhanced
                  functionality, seamless integration, improved automation,
                  streamlined processes, and enhanced user experience.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  Does your company have certified Salesforce developers?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                Yes, we have certified Salesforce developers who excel at
                solving complex problems. Hire our certified Salesforce
                developers to drive your project success.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What is the difference between Salesforce Developer and
                  Salesforce Development Company?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                When you hire a Salesforce developer, they become part of your
                team, whereas a Salesforce development company handles
                end-to-end development processes. Choose based on your project
                requirements.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Which industries do you cater to with Salesforce application
                  development services?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We cater to various industries, including healthcare,
                manufacturing, finance, retail, and more, with our Salesforce
                application development services.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo1"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What app development methodologies does your company follow?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo1"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                We follow Agile methodology and have certified Agile and SCRUM
                developers experienced in various development methodologies.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree1"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What other verticals does SmartSphere Technologies serve in
                  Salesforce application development?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree1"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                In addition to Salesforce application development, we provide
                consulting, implementation, migration, integration, maintenance,
                and security services for a comprehensive Salesforce solution.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default SalesApplication;
