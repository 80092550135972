import React from "react";
import "../Styles/DevlopnerPage.css";
import mainAbout from "../Assets/shopdeveop2.png";
import Contactsub from "./Contactsub";
import { HashLink } from "react-router-hash-link";
import NewFaq from "./NewFaq";
import Flag from "./Flag";
import Faq from "./Faq";
import Hidemob from "../Assets/pix.png";
const LMS = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <section className="Hero-Shopfy-section-1 All-hero-main Section for-baground-images2">
        <div className="container p-4 main-first-hero-all-content">
          <div className="row p-2">
            <div className="col-lg-6 side-heading-para-content">
              <h1 className="main-hero-h1 py-3">
                Custom LMS Development Services
              </h1>
              <p className="main-hero-para-main-div">
                Enhance your learning experience through our specialized custom
                LMS development services. Our team of LMS developers devises
                effective strategies to address your unique business
                requirements and create intuitive LMS applications.
              </p>
              <div className="btnnn py-4">
                <HashLink to={"#contact-us"}>
                  <button className="main-home-shopyt-btn ">
                    Request A Free Consultation{" "}
                  </button>
                </HashLink>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>

      {/* New second Section */}

      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2-">
                  Digitalize Learning with LMS Development "Learning knows no
                  bounds."
                </h2>
                <p className="about-main-content-parap">
                  In recent times, platforms like Udemy, Skillshare, and
                  LinkedIn Learning have demonstrated that learning is not
                  confined to specific age groups or traditional classrooms. The
                  ability to learn anything, anytime, and from the comfort of
                  one's own home has become a reality.<br></br> <br></br>The
                  COVID-19 pandemic has further accelerated the need for digital
                  learning solutions. Today, employees expect access to digital
                  learning tools such as a Learning Management System (LMS) to
                  prepare them for the future.<br></br> <br></br>
                  Custom LMS development plays a crucial role in keeping
                  learners updated with the latest learning materials and
                  providing personalized content recommendations. As an
                  experienced LMS development company, we understand the
                  importance of creating an LMS solution that enhances the
                  accessibility of the learning environment. You can rely on us
                  to meet your unique custom requirements!<br></br>
                  Our comprehensive LMS development services cover everything
                  from assisting you in selecting the right features and
                  technologies to developing LMS solutions from scratch or
                  migrating from an existing LMS. Additionally, you have the
                  option to hire LMS developers from our team dedicated to your
                  custom LMS development needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileimg">
          <img src={Hidemob} />
        </div>
      </section>

      <Contactsub />

      <section className="main-services-card-main-div p-5 ">
        <div className="contianer p-md-5" style={{ marginTop: "50px" }}>
          <div className="row p-2">
            <h1 className="main-serv-ces-h1-head">
              Our LMS Development Services and Expertise
            </h1>

            <p className="main-services-para-main-card-top py-3">
              At SmartSphere Technologies, we specialize in delivering robust and{" "}
              <br></br>
              versatile LMS solutions tailored to your specific e-learning
              <br></br>
              needs. Our services and expertise include
            </p>

            <div className="col-md-4 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">Custom LMS Development</p>
              <p className="main-top-bottom-content">
                We offer end-to-end LMS development services, creating
                cross-platform and feature-rich custom LMS solutions for
                educational institutions and corporate organizations.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Rich Learning Environments</p>
              <p className="main-top-bottom-content">
                Our LMS developers can help you create engaging and interactive
                learning experiences by incorporating elements like 2D and 3D
                graphics, avatars, quests, badges, and more. We specialize in
                gamification and real-time communication tools.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">
                Integrated Plugins and Module Development
              </p>
              <p className="main-top-bottom-content">
                If you already have an existing LMS solution, we can enhance its
                performance and capabilities by developing custom plugins and
                modules to meet your unique requirements.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">LMS Migration</p>
              <p className="main-top-bottom-content">
                We facilitate seamless migration of your LMS system from one
                tech stack to another e-learning platform, ensuring a smooth
                transition without compromising your data or ongoing processes.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Support and Maintenance</p>
              <p className="main-top-bottom-content">
                With our round-the-clock support, routine system check-ups, bug
                detection and resolution, and regular updates, we ensure that
                your e-learning solutions operate with 99% uptime and optimal
                performance.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card set">
              <p className="card-top-main">
                Benefits of LMS Software Development
              </p>
              <p className="main-top-bottom-content">
                Implementing a custom e-learning platform through LMS software
                development can bring numerous benefits to educational
                institutions and corporate sectors:
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Personalized Learning Experience</p>
              <p className="main-top-bottom-content">
                Custom LMS solutions enable personalized learning experiences
                through features like custom dashboards and visual elements,
                offering tailored course recommendations based on individual
                preferences.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">
                Reduced Learning and Development Costs
              </p>
              <p className="main-top-bottom-content">
                By providing a centralized platform for storing and retrieving
                learning materials, assessments, and training resources, custom
                LMS solutions eliminate the need for infrastructure costs and
                printed materials, resulting in significant cost savings.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Performance Evaluation</p>
              <p className="main-top-bottom-content">
                Educators can evaluate the effectiveness of training programs
                through detailed reports and statistics, enabling them to assess
                whether the courses meet objectives and learner expectations.
              </p>
            </div>

            <div className="col-md-4 col-sm-8 card-main-div-main-card">
              <p className="card-top-main">Achievement of Targeted Goals</p>
              <p className="main-top-bottom-content">
                Custom LMS development enhances the learning experience by
                granting learners access to various resources such as PDFs,
                videos, eBooks, podcasts, regardless of their location and time
                zone. This ensures effective knowledge dissemination and
                facilitates the achievement of targeted learning goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid contt">
          <div className="container" style={{ textAlign: "center" }}>
            <h1 className="arrivals-head-main-top-my-bottom">
              Reduced Learning and Development Costs
            </h1>
            <p className="arrival-main-bottom-top-head">
              By providing a centralized platform for storing and retrieving
              learning materials, assessments, and training resources, custom
              LMS solutions eliminate the need for infrastructure costs and
              printed materials, resulting in significant cost savings.
            </p>
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Performance Evaluation:
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Educators can evaluate the effectiveness of training
                    programs through detailed reports and statistics, enabling
                    them to assess whether the courses meet objectives and
                    learner expectations.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Achievement of Targeted Goals
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Custom LMS development enhances the learning experience by
                    granting learners access to various resources such as PDFs,
                    videos, eBooks, podcasts, regardless of their location and
                    time zone.
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Benefits of LMS Software Development
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Implementing a custom e-learning platform through LMS
                    software development can bring numerous benefits to
                    educational institutions and corporate sectors:
                  </p>
                </div>
              </div>
              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="newcardd">
                  <p className="arrival-main-top-boottom">
                    Personalized Learning Experience
                  </p>
                  <p className="arricval-card-content-main-last-top">
                    Custom LMS solutions enable personalized learning
                    experiences through features like custom dashboards and
                    visual elements, offering tailored course recommendations
                    based on individual preferences.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Second-div-2-pictue-about">
        <div className="container ">
          <div className="row p-2">
            <div className="col-lg-6 image-main-section">
              <img src={mainAbout} alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6 main-content-about-all py-5">
              <div className="py-5">
                <h2 className="about-main-conetnetn-h2">
                  Why Choose Us for Your Custom LMS Software Development Project
                </h2>
                <p className="h2-h3-h4">
                  We have been in the eLearning industry for more than 16 years,
                  and we have gained a reputation as eLearning and LMS
                  development specialists.
                </p>
                <ul className="newul">
                  <li>Experienced LMS Developers</li>
                  <li>Scalability as per demand</li>

                  <li>Solution with extensive features</li>
                  <li>Various engagement models to choose from</li>
                  <li>Timely delivery</li>
                  <li>Agile approach</li>
                  <li>Authorization of code</li>
                  <li>Efficient management</li>
                </ul>
                <HashLink to={"#contact-us"}>
                  <button className="bolet-btn-main">
                    Request Consultation
                  </button>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="container cont container my-5"
        style={{ padding: "15px" }}
      >
        <h4 className="head3">Frequently Asked Questions</h4>
        <h6 className="Everything my-4">
          Everything you need to know about the product and billing.
        </h6>

        <div className="accordion w-100 cont2" id="basicAccordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  What factors influence the cost of a custom learning
                  management system?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                <p>
                  The cost of developing an LMS depends on project size,
                  complexity, feature requirements, and the expertise of the
                  development company. It's recommended to consult with LMS
                  development experts for an accurate quote. Contact our team to
                  discuss your project and get a personalized cost estimate.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="listT">
                  What are the main objectives of using a learning management
                  system?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                A learning management system aims to streamline learning
                processes by facilitating planning, integration, and assessment.
                It enables organizations to train employees regardless of their
                location and time zone. LMS is an invaluable tool for optimizing
                training effectiveness in various organizations and
                institutions.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="listT">
                  What are the benefits of a learning management system for
                  educational institutions?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                institutions? A learning management system provides educational
                institutions with a centralized platform for managing and
                delivering online courses, tracking student progress, conducting
                assessments, and facilitating communication between students and
                instructors. It enhances the learning experience, grants access
                to valuable resources, and promotes effective remote learning.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#basicAccordionCollapseOne1"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <h6 className="listT">
                  Is it possible to integrate a learning management system with
                  existing software systems?
                </h6>
              </button>
            </h2>
            <div
              id="basicAccordionCollapseOne1"
              className="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-mdb-parent="#basicAccordion"
              style={{}}
            >
              <div className="accordion-body">
                systems? Yes, an LMS can be integrated with existing software
                systems such as HR systems, content management systems, video
                conferencing tools, or CRM platforms. Integration enables
                seamless data exchange, streamlines workflows, and enhances
                overall operational efficiency. Our team can assess integration
                possibilities based on your specific infrastructure.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Flag />
      <NewFaq />
    </div>
  );
};

export default LMS;
